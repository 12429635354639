import "./App.css";
import { Route, Routes, HashRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import Error from "../src/pages/Error";
import Loginpage from "./pages/Loginpage";
import AddSuperCategory from "./pages/add-edit/superCategory/AddSuperCategory";
import ListSuperCategory from "./pages/add-edit/superCategory/ListSuperCategory";
import AddCategory from "./pages/add-edit/category/AddCategory";
import ListCategory from "./pages/add-edit/category/ListCategory";
import { getSuperCategory } from "./redux/superCategory/superCategorySlice";
import { getCategory } from "./redux/category/categorySlice";
import { getSubCategory } from "./redux/subCategory/subCategorySlice";
import AddSubCategory from "./pages/add-edit/subcategory/AddSubCategory";
import ListSubCategory from "./pages/add-edit/subcategory/ListSubCategory";
import UpdateCategory from "./pages/add-edit/category/UpdateCategory";
import UpdateSubCategory from "./pages/add-edit/subcategory/UpdateSubCategory";
import UpdateSuperCategory from "./pages/add-edit/superCategory/UpdateSuperCategory";
import { getProperty } from "./redux/property/PropertySlice";
import AllProperties from "./pages/add-edit/property/AllProperties";


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const initialSetup = async () => {
      try {
        // getSuperCategory
        dispatch(getSuperCategory());

        // getCategory
        dispatch(getCategory());

        // getSubCategory
        dispatch(getSubCategory());

        // getProperty
        dispatch(getProperty());

      } catch (error) { }
    };
    initialSetup();
  }, []);





  return (
    <div className="wrapper">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route path="/admin" exact element={<MainLayout />}>
            <Route index element={<Dashboard />} />

            {/* supercategory */}
            <Route
              path="/admin/addsupercategory"
              element={<AddSuperCategory />}
            />

            <Route
              path="/admin/listsupercategory"
              element={<ListSuperCategory />}
            />

            <Route
              path="/admin/updatesupercategory/:slugurl"
              element={<UpdateSuperCategory />}
            />

            {/* category */}
            <Route path="/admin/addcategory" element={<AddCategory />} />
            <Route path="/admin/listcategory" element={<ListCategory />} />
            <Route
              path="/admin/updatecategory/:slugurl"
              element={<UpdateCategory />}
            />
            {/* subcategory */}
            <Route path="/admin/addsubcategory" element={<AddSubCategory />} />
            <Route
              path="/admin/listsubcategory"
              element={<ListSubCategory />}
            />
            <Route
              path="/admin/updatesubcategory/:slugurl"
              element={<UpdateSubCategory />}
            />
            {/* AllProperties */}
            <Route path="/admin/all-properties" element={<AllProperties />} />


          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
