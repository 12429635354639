import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import BeatLoader from "react-spinners/BeatLoader";
import { Modal, ModalHeader } from "reactstrap";
import { PuffLoader } from "react-spinners";
import { propertyUpdate } from "../../../redux/property/PropertySlice";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";

const AllProperties = () => {
  const { propertyAll, isPropertyLoading } = useSelector(
    (store) => store.Property
  );
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [filterProperty, setFilterProperty] = useState([]);
  const [properties, setProperties] = useState([]);
  const [temploading, setTempLoading] = useState(false);

  const [selectProperty, setSelectProperty] = useState("");
  const [model, setModel] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/property/all`;
      const fetchProperty = await axios.get(url);
      if (fetchProperty.data.success === true) {
        const propertiFetch = fetchProperty.data.properties;
        setProperties(propertiFetch.reverse());
        setTempLoading(false);
      }
    };
    fetchData();
    // setInterval(async () => {
    //   fetchData();
    // }, 500);
  }, []);

  useEffect(() => {
    const result = properties.filter((order) => {
      return order.propertyID.toLowerCase().match(search.toLowerCase());
    });
    setFilterProperty(result);
  }, [search, properties]);

  const nextProcesssClick = (e, order) => {
    setModel(true);
    setSelectProperty(order);
  };

  const updateClick = async (e) => {
    const formData = {
      propertyStatus: "Active",
      propertyId: selectProperty._id,
    };
    const data = await dispatch(propertyUpdate(formData));

    const url = `${Baseurl}/api/v1/property/updateby/${formData.propertyId}`;
    const updateProperty = await axios.put(url);
    if (updateProperty.data.success === true) {
      const propertiFetch = updateProperty.data.property;
      const updatePro = filterProperty.map((item) => {
        if (item._id === selectProperty._id) {
          item.propertyStatus = "Active";
        }
        return item;
      });

      setFilterProperty(updatePro);
    }

    setModel(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "Property ID",
      width: 100,
      //   headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex" style={{ textTransform: "uppercase" }}>
            {params.row.propertyID}
          </div>
        );
      },
    },
    {
      headerName: "Post User Name",
      width: 200,
      //   headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex" style={{ textTransform: "capitalize" }}>
            {params.row.postUserName}
          </div>
        );
      },
    },
    {
      headerName: "Project Name",
      width: 200,
      //   headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex  my-4" style={{ textTransform: "capitalize" }}>
            {params.row.apartment}
          </div>
        );
      },
    },

    {
      field: "ACTIONS",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex  flex-column my-3">
            {params.row.propertyStatus === "Underscreening" ? (
              <>
                <Button
                  variant="contained"
                  onClick={(e) => nextProcesssClick(e, params.row)}
                  style={{ background: "#556ee6" }}
                >
                  Process
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      //   headerAlign: "center",
      //   align: "center",
    },
    {
      field: "locality",
      headerName: "Locality",
      width: 150,
      //   headerAlign: "center",
      //   align: "center",
    },

    {
      field: "visiblePrice",
      headerName: "Budget",
      width: 100,
      //   headerAlign: "center",
      //   align: "center",
    },

    {
      field: "Post DateTime",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column">
            <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
            <h6>{moment(params.row.createdAt).format("h:mm:ss a")}</h6>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-footer">
              <h5>PROPERTY ALL</h5>
            </div>
            <div className="card-body">
              <Row className="g-2  m-2 w-25">
                <Col md>
                  <Form.Control
                    type="text"
                    placeholder="Search by PropertyId"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ textTransform: "uppercase" }}
                  />
                </Col>
              </Row>
            </div>
            {temploading ? (
              <>
                <div className="d-flex justify-content-center loadingMain">
                  <PuffLoader
                    color={"#36d7b7"}
                    loading={temploading}
                    size={30}
                    className="mt-2 mx-2"
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{ height: 820, width: "100%" }}
                  className="d-flex m-auto text-align-center"
                >
                  <DataGrid
                    rows={filterProperty}
                    columns={columns}
                    getRowId={(row) => row._id}
                    rowHeight={100}
                    getRowHeight={() => "auto"}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    // getRowClassName={(params) =>
                    //   params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    // }
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                    }}
                  />
                </div>
              </>
            )}
          </div>

          <Modal size="md" isOpen={model} toggle={() => setModel(!model)}>
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want{" "}
                      <span style={{ color: "red" }}>Property processing</span>{" "}
                      this PropertyID:
                      {selectProperty !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                              textTransform: "uppercase",
                            }}
                          >
                            {selectProperty._id.slice(18)}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => updateClick(e)}
                        >
                          Process
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
        </div>
      </Fragment>
    </>
  );
};

export default AllProperties;
