import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  propertyAll: [],
  isLoading: true,
  isPropertyLoading: false,
};
//get property
export const getProperty = createAsyncThunk(
  "Property/getProperty",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/property/all`;
      const resp = await axios(url);
      return resp.data.properties;
    } catch (error) {
      return thunkAPI.rejectWithValue("supercategories Not Found");
    }
  }
);

//property update
export const propertyUpdate = createAsyncThunk(
  "Property/propertyUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/property/updateby/${formData.propertyId}`;

      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Property Not Updated");
    }
  }
);

const PropertySlice = createSlice({
  name: "Property",
  initialState,
  reducers: {},
  extraReducers: {
    [getProperty.pending]: (state) => {
      state.isPropertyLoading = true;
    },
    [getProperty.fulfilled]: (state, action) => {
      state.isPropertyLoading = false;
      state.propertyAll = action.payload.slice().reverse();
      state.propertyAll = state.propertyAll.filter(
        (property) => property.userId !== state.userId
      );

    },
    [getProperty.rejected]: (state, action) => {
      state.isPropertyLoading = true;
    },


    //property upadted
    [propertyUpdate.pending]: (state) => {
      state.isPropertyLoading = true;
    },

    [propertyUpdate.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.propertyAll = state.propertyAll.filter(
          (property) => property._id !== action.payload.property._id
        );
        state.propertyAll = [...state.propertyAll, action.payload.property];
        state.propertyAll = state.propertyAll.filter(
          (property) => property.userId !== state.userId
        );
      }
      state.isPropertyLoading = false;
    },

    [propertyUpdate.rejected]: (state, action) => {
      state.isPropertyLoading = true;
    },
  },
});
export const { } = PropertySlice.actions;
export default PropertySlice.reducer;
