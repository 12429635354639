
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  // subcategorytotal: [],
  subcategorytotal: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal"))
    : [],

  mobileimage: "",
  desktopimage: "",
  isSubCatmobileImageLoading: true,
  isSubDeskImageLoading: true,
  isLoading: true,
  subCategoryLoading: true,
  deleteSubCatLoading: true,
  checkSlugurl: true,
};

export const getSubCategory = createAsyncThunk(
  "SubCategory/getSubCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcategory/all`;
      const resp = await axios(url);
      return resp.data.subcategory;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const subCategoryPost = createAsyncThunk(
  "subCategory/subCategoryPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/subcategory/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Not create");
    }
  }
);

export const subCategoryImages = createAsyncThunk(
  "category/categoryImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/subcategory/subcategoryimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategoryimages Not create");
    }
  }
);

export const validateSlugUrl = createAsyncThunk(
  "subcategory/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/subcategory/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const subCategoryUpdate = createAsyncThunk(
  "subCategory/subCategoryUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/updateby/${formData.subcatid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Not create");
    }
  }
);
export const subCategoryDelete = createAsyncThunk(
  "subCategory/subCategoryDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/${id}`;
      const resp = await axios.delete(url, id, config);
      console.log(resp.data);
      const myreturn = {
        success: resp.data.success,
        id: id,
      }
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Not create");
    }
  }
);

const SubCategorySlice = createSlice({
  name: "subCategories",
  initialState,
  reducers: {
    updateSubcatMobileImage(state, action) {
      state.mobileimage = action.payload;
      state.isSubCatmobileImageLoading = false;
    },
    updateSubcatDeskImage(state, action) {
      state.desktopimage = action.payload;
      state.isSubDeskImageLoading = false;
    },
    deleteSubcategory(state, action) {
      state.subcategorytotal = state.subcategorytotal.filter((subcategory) => subcategory._id !== action.payload);
    },
  },
  extraReducers: {

    [getSubCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [getSubCategory.fulfilled]: (state, action) => {
      state.subcategorytotal = action.payload;
      localStorage.setItem("subcategorytotal", JSON.stringify(state.subcategorytotal));
      state.isLoading = false;
      state.subCategoryLoading = false;
    },
    [getSubCategory.rejected]: (state, action) => {
      state.isLoading = true;
    },



    [subCategoryPost.pending]: (state) => {
      state.subCategoryLoading = true;
    },

    [subCategoryPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategorytotal = [...state.subcategorytotal, action.payload.subcategory];
        localStorage.setItem("subcategorytotal", JSON.stringify(state.subcategorytotal));
      }
      state.subCategoryLoading = false;
    },

    [subCategoryPost.rejected]: (state, action) => {
      state.subCategoryLoading = true;
    },



    [subCategoryUpdate.pending]: (state) => {
      state.subCategoryLoading = true;
    },

    [subCategoryUpdate.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategorytotal = state.subcategorytotal.filter((subcategory) => subcategory._id !== action.payload.subcategory._id);
        state.subcategorytotal = [...state.subcategorytotal, action.payload.subcategory];
        state.mobileimage = "";
        state.desktopimage = "";
        state.isSubCatmobileImageLoading = true;
        state.isSubDeskImageLoading = true;
        localStorage.setItem("subcategorytotal", JSON.stringify(state.subcategorytotal));
      }
      state.subCategoryLoading = false;
    },

    [subCategoryUpdate.rejected]: (state, action) => {
      state.subCategoryLoading = true;
    },



    [subCategoryDelete.pending]: (state) => {
      state.deleteSubCatLoading = true;
    },

    [subCategoryDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategorytotal = state.subcategorytotal.filter((subcategory) => subcategory._id !== action.payload.id);
        localStorage.setItem("subcategorytotal", JSON.stringify(state.subcategorytotal));
      }
      state.deleteSubCatLoading = false;
    },

    [subCategoryDelete.rejected]: (state, action) => {
      state.deleteSubCatLoading = true;
    },



    [subCategoryImages.pending]: (state) => {
      state.isSubDeskImageLoading = true;
    },

    [subCategoryImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.desktopimage = action.payload.desktopImages;
        state.mobileimage = action.payload.mobileimages;

      }
      state.isSubDeskImageLoading = false;
      state.isSubCatmobileImageLoading = false;
    },

    [subCategoryImages.rejected]: (state, action) => {
      state.isSubDeskImageLoading = true;
    },
  },
});
export const { updateSubcatMobileImage, updateSubcatDeskImage, deleteSubcategory } = SubCategorySlice.actions;
export default SubCategorySlice.reducer;