import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./redux/Athentication/AuthSlice";
import superCategoryReducer from "./redux/superCategory/superCategorySlice"
import categoryAllReducer from "./redux/category/categorySlice";
import subCategoriesReducer from "./redux/subCategory/subCategorySlice";
import PropertyReducer from "./redux/property/PropertySlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    superCategory: superCategoryReducer,
    categoryAll: categoryAllReducer,
    subCategories: subCategoriesReducer,
    Property: PropertyReducer,
  },
});