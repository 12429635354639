import React, { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  subCategoryUpdate,
  subCategoryImages,
  validateSlugUrl,
  updateSubcatMobileImage,
  updateSubcatDeskImage,
} from "../../../redux/subCategory/subCategorySlice";

const UpdateSubCategory = () => {
  const { mobileimage, desktopimage, isSubDeskImageLoading } = useSelector(
    (store) => store.subCategories
  );
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const [names, setNames] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [supercat, setSupercat] = useState("");
  const [catbySupercat, setCatbySupercat] = useState([]);
  const [supercatid, setSupercatid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [subcatId, setSubcatId] = useState("");
  const [priority, setPriority] = useState("");

  const config = {
    Headers: { "Content-Type": "application/json" },
  };

  const params = useParams();

  useEffect(() => {
    const subcatUrl = params.slugurl;

    const updatesubcat = subcategorytotal.find(
      (subcat) => subcat.slugUrl === subcatUrl
    );
    console.log(updatesubcat);
    setSubcatId(updatesubcat._id);

    setNames(updatesubcat.name);
    setSlugUrl(updatesubcat.slugUrl);
    setPriority(updatesubcat.priority);
    setSupercat(updatesubcat.superCategory);
    setSupercatid(updatesubcat.superCategoryId);
    setCategory(updatesubcat.category);
    setCategoryid(updatesubcat.categoryId);
  }, []);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setNames(value);
    //  setErrorcolor("green");
    setCheckSlugUrl("");
    //  setError("category available");
    const subcatSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(subcatSlug);
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: names.charAt(0).toUpperCase() + names.slice(1).toLowerCase(),
      slugUrl: slugUrl,
      superCategory: supercat,
      superCategoryId: supercatid,
      category: category,
      categoryId: categoryid,
      subcatid: subcatId,
      priority: priority,
    };

    const data = await dispatch(subCategoryUpdate(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Sub-Category Updated");
      } else {
        alert(data.message);
      }
    }
  };

  const selectsuperCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercatid(value);
    setSupercat(text);
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercatid
    );
    setCatbySupercat(catbysupercat);
  }, [supercatid, categorytotal]);

  const dispatch = useDispatch();



  const priorityChange = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPriority(value);
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Update Sub-Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category Name
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectsuperCategory(e);
                        }}
                        value={superCatTotal._id}
                        name={superCatTotal.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {superCatTotal.map((data) => (
                          <option
                            selected={data._id === supercatid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category Name
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={catbySupercat._id}
                        name={catbySupercat.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {catbySupercat.map((data) => (
                          <option
                            selected={data._id === categoryid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={names}
                        onChange={(e) => verifyslugurl(e)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2 m-2 w-50">
                    <Col sm>
                      <Form.Control
                        type="text"
                        placeholder="priority..."
                        value={priority}
                        onChange={(e) => priorityChange(e)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Update Sub Category
                  </button>
                </div>
                {/* <div className="card-footer ">
                   {checkslugUrl !== "" &&
                   supercatid !=="" &&
                    names !== "" && categoryid !=="" &&
                    !isSubDeskImageLoading ? (
                     <button className="btn btn-primary" type="submit">
                       Update Sub Category
                     </button>
                   ) : (
                     <button className="btn btn-primary" disabled type="submit">
                       Update Sub Category
                     </button>
                   )}
                 </div> */}
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateSubCategory;
